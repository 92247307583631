import React from "react"
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import './step-card.css'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 290,
    marginLeft: 0,
  },
  content: {
    padding: 24,
  },
}));

const StepCard = (props) => {
	const cardStyles = useStyles();
	return(
	<Card variant="outlined" className={cardStyles.root}>
		<CardHeader title={<Typography variant='h6'> { props.header }</Typography>}
					avatar= { <Avatar className="avatar-circle"> { props.avatar } </Avatar> }
					className="card-title" />
		<CardMedia>
			{ props.media }
		</CardMedia>
		<CardContent>
			{ props.children }
		</CardContent>
	</Card>
	)}

export default StepCard;