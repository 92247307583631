import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import StepCard from "../components/step-card"
import CollaborateImage from "../components/collaborate-image"
import WireframeImage from "../components/wireframe-image"
import MvpImage from "../components/mvp-image"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import ServerImage from "../components/server-image"
import ContactUsButton from "../components/contact-us-button"

const HowWeWork = () => (
  <Layout>
    <SEO title="How we work." />
    <div className="page-body">
      <Typography variant="h3" color="secondary" className="clients-page-title">
        How we work
      </Typography>
      <Divider />
      <Typography variant="subtitle1" className="how-we-work-intro">
        Here is an outline of how we approach custom software projects. The
        bottom line is that we will not charge you anything until you have a
        custom solution that you are 100% happy with. We also will not hide any
        ongoing program support fees to debug issues with our software.
      </Typography>
      <br />
      <Divider />
      <br />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item>
          <StepCard header="Discovery" media={<CollaborateImage />} avatar="1">
            <Typography variant="body1" className="card-text">
              The first step is to get together and discuss what you would like
              us to automate. Typically this centers around a particular
              'pain-point' that is acting as a bottle-neck in your current
              operation. During this time it is a good idea to discuss the
              immediate needs as well as any long-term technological goals you
              may have. This will allow us to keep the big picture in mind as we
              start planning a solution.
              <Typography variant="body1" className="charge-statement">
                We do not charge anything for the discovery process.
              </Typography>
            </Typography>
          </StepCard>
        </Grid>
        <Grid item>
          <StepCard header="Proposal" media={<WireframeImage />} avatar="2">
            <Typography variant="body1" className="card-text">
              Next, we will take the discovery notes back to our shop and figure
              out the best way to tackle the problem. We will keep your
              immediate needs as well as your long-term plans in mind. If we
              anticipate a lot of initial iterations and changes we may suggest
              starting off with a low-code platform such as FileMaker. When we
              are done we will produce a 'letter of understanding' indicating
              our suggested platform, schedule and cost.
              <Typography variant="body1" className="charge-statement">
                We do not charge anything for the propsal process.
              </Typography>
            </Typography>
          </StepCard>
        </Grid>
        <Grid item>
          <StepCard header="MVP" media={<MvpImage />} avatar="3">
            <Typography variant="body1" className="card-text">
              Once you give us the go-ahead we will start developing a MVP or,
              Minimum Viable Product. Which is an un-polished product that is
              fully functional. This is where we will work closely with you and
              your team to fine-tune the user-interfaces and functionality. We
              will work on the project together until you are 100% happy with
              it.
              <Typography variant="body1" className="charge-statement">
                At the end of this process we invoice the agreed price.
              </Typography>
            </Typography>
          </StepCard>
        </Grid>
      </Grid>
      <br />

      <Paper className="step-four">
        <div className="paper-header">
          <Avatar className="avatar-circle paper-avatar"> 4 </Avatar>
          <Typography variant="h6" className="card-title">
            {" "}
            Ongoing Support and Development{" "}
          </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid item className="step-four-text" item xs={12} sm={6}>
            <Typography variant="body1">
              Once the solution is complete and ready for use we will always be
              available for tech support. We do not charge extra for "support
              programs". If there is an issue with the software we built, we
              will be there to fix it. Often we continue working with our
              clients to expand and refine their systems on an ongoing basis. We
              are also happy to look after setting up secure cloud hosting to
              ensure your new software is secure, scalable and available. We use
              industry-leading hosting providers with servers available
              throughout the world.
            </Typography>
          </Grid>
          <Grid item className="server-image" item xs={12} sm={6}>
            <ServerImage />
          </Grid>
        </Grid>
      </Paper>
    </div>
    <Divider className="how-we-work-bottom-divider" />
    <Typography
      variant="subtitle1"
      className="how-we-work-intro"
      align="center"
    >
      Do you have questions? Ready to get started?
    </Typography>
    <br />
    <div className="button-area">
      <ContactUsButton buttonText="Contact Us" />
    </div>
    <Divider className="how-we-work-bottom-divider" />
  </Layout>
)
export default HowWeWork
